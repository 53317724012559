/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import Image from 'next/image'
import analyticsSS from '@/images/landing-page/analytics.png'
import EmailForm from './EmailForm'
const SignupButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      type="submit"
      className="rounded-md inline-block bg-white w-full px-3.5 py-2.5 text-sm font-semibold text-indigo-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
    > Try free plan </button>
  )
}
export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-16 lg:px-8">
        <div className="overflow-hidden rounded-lg bg-indigo-700 shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:px-20 xl:py-20">
            <div className="lg:self-center">
              <h2 className="text-2xl font-bold tracking-tight text-white sm:text-3xl">
                <span className="block">Dive into success today!</span>
                <span className="block">Get started with our 7 days free trial</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                Send emails and whatsapp messages to your customers with ease and at a fraction of the cost.
              </p>
              <div className='mt-10'>
                <EmailForm SignupButton={SignupButton} />
              </div>
            </div>
          </div>
          <div className="aspect-h-3 aspect-w-5 -mt-6 md:aspect-h-1 md:aspect-w-2">
            <Image
              src={analyticsSS}
              alt="Analytics Screenshots"
              className="translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
