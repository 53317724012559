import aws from '@/images/logos/aws-white.svg'
import sendgrid from '@/images/logos/sendgrid-white.svg'
import zapier from '@/images/logos/zapier.svg'
import Image from 'next/image'

export default function Example() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
          <div className="mx-auto w-full max-w-xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white">
              Integrate with services you love
            </h2>
            <div className="mt-8 flex items-center gap-x-6">
              <a
                href="/signup"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Try for free <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div className="mx-auto grid w-full max-w-xl grid-cols-2 items-center gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8">
            <Image
              src={aws}
              width="200"
              height={48}
              alt="aws"
              unoptimized
              className="mx-auto"
            />
            {/* <img
              className="max-h-12 w-full object-contain object-left"
              src="/images/email-providers/aws.png"
              alt="Tuple"
              width={105}
              height={48}
            /> */}

            <Image
              src={sendgrid}
              width="200"
              height={48}
              alt="sendgrid"
              unoptimized
              className="mx-auto"
            />
            <Image
              src={zapier}
              width="200"
              height={48}
              alt="zapier"
              unoptimized
              className="mx-auto"
            />
            {/* <img
              className="max-h-12 w-full object-contain object-left"
              src="https://tailwindui.com/img/logos/reform-logo-white.svg"
              alt="Reform"
              width={104}
              height={48}
            /> */}
            {/* <img
              className="max-h-12 w-full object-contain object-left"
              src="https://tailwindui.com/img/logos/savvycal-logo-white.svg"
              alt="SavvyCal"
              width={140}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain object-left"
              src="https://tailwindui.com/img/logos/laravel-logo-white.svg"
              alt="Laravel"
              width={136}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain object-left"
              src="https://tailwindui.com/img/logos/transistor-logo-white.svg"
              alt="Transistor"
              width={158}
              height={48}
            />
            <img
              className="max-h-12 w-full object-contain object-left"
              src="https://tailwindui.com/img/logos/statamic-logo-white.svg"
              alt="Statamic"
              width={147}
              height={48}
            /> */}
          </div>
        </div>
      </div>
    </div>
  )
}
