import Image from 'next/image'
import VolopayLogo from '@/images/testimonials/volopaylogo.png'
import volopayUser from '@/images/testimonials/volopaycto.jpeg'
import RiwayatLogo from '@/images/testimonials/rivayat.jpg'
import riwayatUser from '@/images/testimonials/rivayatceo.jpeg'
export default function Example() {
  return (
    <div className="pt-24 sm:pt-32">
      <div className="mb-100 mx-auto max-w-xl text-center">
        <p className="mt-2 text-2xl sm:text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Check out what our customers have to say about us.
        </p>
      </div>
      <section className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
              <Image
                className="h-9 self-start"
                src={VolopayLogo}
                alt="volopay logo"
                priority
              />
              <figure className="mt-10 flex flex-auto flex-col justify-between">
                <blockquote className="text-lg leading-8 text-gray-900">
                  <p>
                    We are extremely satisfied with CampaignHQ. The improved
                    pricing, user-friendly platform, and efficient campaign
                    creation have made our product email updates efforts much
                    more streamlined and effective. We highly recommend
                    CampaignHQ to anyone seeking a hassle-free and
                    cost-effective solution for their email marketing needs
                  </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                  <Image
                    className="h-14 w-14 rounded-full bg-gray-50"
                    src={volopayUser}
                    alt="Rajesh Raikwar"
                    priority
                  />
                  {/* <img
                  className="h-14 w-14 rounded-full bg-gray-50"
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                /> */}
                  <div className="text-base">
                    <div className="font-semibold text-gray-900">
                      Rajesh Raikwar
                    </div>
                    <div className="mt-1 text-gray-500">
                      CTO of Volopay (YC company)
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
            <div className="flex flex-col border-t border-gray-900/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
              <Image
                className="w-[110px] self-start"
                src={RiwayatLogo}
                alt="volopay logo"
                priority
              />
              <figure className="mt-10 flex flex-auto flex-col justify-between">
                <blockquote className="text-lg leading-8 text-gray-900">
                  <p>
                    CampaignHQ's seamless Shopify integration, user-friendly
                    interface, and stunning UI have transformed our email
                    marketing. Creating visually appealing emails is effortless,
                    resulting in higher conversions. The insightful analytics
                    and exceptional customer support make it a must-have for
                    e-commerce businesses.
                  </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                  <Image
                    src={riwayatUser}
                    className="h-14 w-14 rounded-full bg-gray-50"
                    alt="Priya"
                    priority
                  />

                  <div className="text-base">
                    <div className="font-semibold text-gray-900">Priya</div>
                    <div className="mt-1 text-gray-500">
                      CEO of Riwayat & TheToonStore.co
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
