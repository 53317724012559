import EmailForm from "./EmailForm"
const SignupButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      type="submit"
      className="rounded-md inline-block w-full bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
    > Try free plan </button>
  )
}

export default function Support() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900">
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto  text-center">
          <h2 className="text-2xl sm:text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Get support at every step
            <br/>
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
            Count on our support every step of the way! from start to success, CampaignHQ is here to help you maximize your experience.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <EmailForm SignupButton={SignupButton}/>
          </div>
        </div>
      </div>
      <svg
        viewBox="0 0 1024 1024"
        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
        aria-hidden="true"
      >
        <circle cx={512} cy={512} r={512} fill="url(#8d958450-c69f-4251-94bc-4e091a323369)" fillOpacity="0.7" />
        <defs>
          <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
            <stop stopColor="#7775D6" />
            <stop offset={1} stopColor="#E935C1" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  )
}
