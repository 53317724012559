import { useEffect } from "react"

export default function(){
  const openChat = function () {
    if (Beacon) {
      Beacon('open')
    }
  }

  return (
    <div className="fixed h-[80px] w-[80px] z-10 bottom-[125px] right-[25px] cursor-pointer" onClick={openChat}>
      <div className="rounded-md overflow-hidden border-indigo-500 relative">
        <img src="https://media.tenor.com/tKl1Ft1jcFwAAAAd/cat-lets-talk.gif" className="w-full h-auto inline-block"/>
        <p className="text-white absolute z-10 bottom-[10px] text-center font-semibold text-[14px]">
          Talk with our team
        </p>
      </div>
    </div>
  )
}
