import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: 'What is CampaignHQ?',
    answer:
      'CampaignHQ is an email marketing platform that allows businesses of all sizes to create and send professional email campaigns to their target audience.',
  },
  {
    question: 'What features does CampaignHQ offer?',
    answer:
      'CampaignHQ offers a range of features, including customizable email templates, contact management tools, email scheduling, automation, analytics and reporting, integration with other marketing tools and many more.',
  },
  {
    question: 'Is CampaignHQ easy to use?',
    answer:
      'Yes, CampaignHQ is designed to be user-friendly and intuitive, even for users with little to no marketing experience.',
  },
  {
    question: 'How much does CampaignHQ cost?',
    answer:
      'CampaignHQ offers affordable pricing plans based on the number of contacts you have. We offer free plans and trials on paid plans, making it accessible for businesses of all sizes',
  },
  {
    question: 'Can I try CampaignHQ before purchasing?',
    answer:
      'Yes, CampaignHQ offers a 7-day free trial on all paid plans so that you can try out the platform before committing to a plan.',
  },
  {
    question: 'Does CampaignHQ offer customer support?',
    answer:
      'Yes, CampaignHQ offers customer support via email and live chat, as well as a comprehensive knowledge base to help users troubleshoot any issues.',
  },
  {
    question: 'Can I integrate CampaignHQ with other marketing tools?',
    answer:
      'Yes, CampaignHQ integrates seamlessly with a range of other marketing tools, including CRMs, e-commerce platforms, and social media management tools.',
  },
  {
    question:
      'How can I track the success of my email campaigns with CampaignHQ?',
    answer:
      'CampaignHQ offers a range of analytics and reporting features, allowing you to track metrics such as open rates, click-through rates, and spams. This data can be used to optimize your campaigns and improve your results over time.',
  },
  {
    question: 'Does CampaignHQ provide email services as well?',
    answer: 'Yes, CampaignHQ provides email services as well.',
  },
  {
    question: 'Can I connect my own AWS SES account with CampaignHQ?',
    answer:
      "Yes, you can connect your own AWS SES account with CampaignHQ. Send us a message through chat and we'll help you in setting up AWS SES.",
  },
  {
    question: 'Does CampaignHQ help in setting up AWS SES?',
    answer:
      'Yes, CampaignHQ helps you in setting up AWS SES. Send us a message through chat and we will help you in setting up AWS SES.'
  },
  {
    question: 'Does CampaignHQ provide email templates?',
    answer:
      'Yes, CampaignHQ provides email templates. You can also create your own email templates.'
  },
  {
    question: 'Does CampaignHQ provide email scheduling?',
    answer:
      'Yes, CampaignHQ provides email scheduling. You can schedule your email campaigns to be sent at the optimal time for your subscribers, ensuring that your message is delivered when it\'s most likely to be seen and engaged with.'
  },
  {
    question: 'Does CampaignHQ provide email automation?',
    answer:
      "Yes, CampaignHQ provides email automation. You can set up automated workflows to send emails based on triggers such as a subscriber's birthday or a specific date."
  },
  {
    question: 'Does CampaignHQ provide email analytics?',
    answer:
      'Yes, CampaignHQ provides email analytics. You can track metrics such as open rates, click-through rates, and spams. This data can be used to optimize your campaigns and improve your results over time.'
  },
  {
    question: "Does CampaignHQ provider A/B testing?",
    answer:
      "We are working on adding A/B testing. We'll let you know when it's available."
  },
  {
    question: 'Does CampaignHQ provide email list management?',
    answer:
      'Yes, CampaignHQ provides email list management. You can manage your email lists and subscribers.'
  },
  {
    question: "Does CampaignHQ provider AMP mail?",
    answer:
      "We have plan to launch AMP mails soon. We'll let you know when it's available."
  }
]

const openChat = function () {
  if (Beacon) {
    Beacon('open')
  }
}

export default function FAQ() {
  return (
    <div className="bg-white" id="faqs">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <p className="text-base leading-7 text-gray-600">
            Can’t find the answer you’re looking for? Reach out to our{' '}
            <a
              href="#"
              onClick={openChat}
              className="font-semibold text-indigo-600 hover:text-indigo-500"
            >
              customer support
            </a>{' '}
            team.
          </p>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
